import { useQueryClient } from '@tanstack/vue-query'
import { readonly } from 'vue'
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { Feature, Company, companySchema } from '@/api/useCompany.types'
import { universalTypedFetch } from '@/api/utils/factory'
import { Endpoint, useUrl } from '@/api/utils/url'
import { useAccessManual } from '@/modules/base/composable/useAccess'
import { appsignal } from '@/modules/base/composable/useAppsignal'
import { getCompanyId } from '@/modules/base/composable/useCurrentCompany'
import { Route } from '@/modules/base/config/routesConfig'

const fallbackFeatureRoutes = readonly<[Feature, Route][]>([
  [Feature.Reduction, Route.Dashboard],
  [Feature.Footprint, Route.Footprint],
  [Feature.Reporting, Route.Reporting],
])

export const featuresAccessGuard: NavigationGuard = async (to) => {
  const { getCompaniesCacheFirst } = useFetchCompanyWithDescendants()

  try {
    const companies = await getCompaniesCacheFirst()
    const company = companies?.find(
      (company) => company.id === getCompanyId(to)
    )
    const { can } = useAccessManual(company)

    return checkMatchedRoutesFeatureAccess(to, can)
  } catch (error) {
    appsignal.send(error as Error)
    return false
  }
}

export const checkMatchedRoutesFeatureAccess = (
  to: RouteLocationNormalized,
  can: (feature: Feature) => boolean
) => {
  for (const match of to.matched) {
    const missingFeature = match.meta.requireFeatures?.find(
      (feature) => !can(feature)
    )

    if (!missingFeature) {
      continue
    }

    const redirect = fallbackFeatureRoutes.find(([feature]) => can(feature))

    if (!redirect) {
      throw new Error(
        `Required feature '${missingFeature}' is missing and no fallback route was found`
      )
    }

    return {
      ...to,
      name: redirect[1],
    }
  }

  return true
}

export const useFetchCompanyWithDescendants = () => {
  const queryClient = useQueryClient()
  const { createUrl } = useUrl()

  const queryKey = [Endpoint.Companies]
  const fetchCompanies = () =>
    queryClient.fetchQuery({
      queryKey,
      queryFn: () =>
        universalTypedFetch(
          createUrl(Endpoint.Companies, {
            includeCurrentCompany: true,
            includeDescendantCompanies: true,
          }),
          companySchema.array()
        ),
    })

  const getCompaniesCacheFirst = async () =>
    (queryClient.getQueryState(queryKey)?.isInvalidated
      ? undefined
      : queryClient.getQueryData<Company[]>(queryKey)) ??
    (await fetchCompanies())

  return {
    getCompaniesCacheFirst,
  }
}
